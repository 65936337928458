/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useMemo } from 'react';
import { styled, t } from '@superset-ui/core';
import { Form, FormItem } from 'src/components/Form';
import Select from 'src/components/Select/Select';
import { Col, Row } from 'src/components';
import Button from 'src/components/Button';
import { SvgMapRecord } from './types';

const JustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const rulesRequired = [{ required: true, message: t('Required') }];

export const FormattingPopoverContent = ({
  config,
  onChange,
  sourceValues,
  targetIdsList,
  SvgMapValues,
}: {
  config?: SvgMapRecord;
  onChange: (config: SvgMapRecord) => void;
  targetIdsList: Array<string>;
  sourceValues: Array<string>;
  SvgMapValues: Array<string>;
}) => {
  const options = useMemo(
    () =>
      sourceValues
        .filter(col => !SvgMapValues?.includes(col))
        .map(value => ({ label: value, value })),
    [SvgMapValues, sourceValues],
  );
  const groupIds =
    targetIdsList?.length > 0
      ? targetIdsList.map((option: string) => ({
          value: option,
          label: option,
        }))
      : [];

  return (
    <Form
      onFinish={onChange}
      initialValues={config}
      requiredMark="optional"
      layout="vertical"
    >
      <Row gutter={12}>
        <Col span={12}>
          <FormItem name="value" label={t('value')} rules={rulesRequired}>
            <Select ariaLabel={t('Select value')} options={options} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name="groupIds" label={t('Group ID')} rules={rulesRequired}>
            <Select
              ariaLabel={t('Select ID')}
              options={groupIds}
              mode="multiple"
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem>
        <JustifyEnd>
          <Button htmlType="submit" buttonStyle="primary">
            {t('Apply')}
          </Button>
        </JustifyEnd>
      </FormItem>
    </Form>
  );
};
