/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { t, withTheme, styled, css } from '@superset-ui/core';
import Icons from 'src/components/Icons';
import {
  AddControlLabel,
  Label,
  OptionControlContainer,
} from 'src/explore/components/controls/OptionControls';
import ControlHeader from 'src/explore/components/ControlHeader';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fileType: PropTypes.string,
  fileName: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  setControlValue: PropTypes.func,
  value: PropTypes.object,
};

const defaultProps = {
  label: null,
  description: null,
  fileName: null,
  fileType: null,
  onChange: () => {},
  value: { name: '', content: '' },
};

const FormattersContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.gridUnit}px;
    border: solid 1px ${theme.colors.grayscale.light2};
    border-radius: ${theme.gridUnit}px;
  `}
`;

export const FormatterContainer = styled(OptionControlContainer)`
  &,
  & > div {
    margin-bottom: ${({ theme }) => theme.gridUnit}px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light1};
    height: 100%;
    width: ${theme.gridUnit * 6}px;
    border: none;
    border-right: solid 1px ${theme.colors.grayscale.dark2}0C;
    padding: 0;
    outline: none;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  `}
`;

class FileControl extends React.Component {
  constructor(props) {
    super(props);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.fileReader = new FileReader();
  }

  onChange(value) {
    this.props.onChange(value);
  }

  onLoad(fileReader, file) {
    this.onChange({
      name: file.name,
      content: fileReader.result,
      type: file.type,
      size: file.size,
    });
  }

  deleteFile() {
    this.onChange({});
  }

  onSelectFile(e) {
    const file = e.target.files[0];
    this.fileReader = new FileReader();
    this.fileReader.readAsText(file, 'utf-8');
    this.fileReader.addEventListener('load', () =>
      this.onLoad(this.fileReader, file),
    );
  }

  render() {
    const { theme } = this.props;
    const valueName = this.props.value.name;
    const valueContent = this.props.value.content;
    return (
      <div>
        <ControlHeader {...this.props} />

        <FormattersContainer>
          {valueContent ? (
            <FormatterContainer key={0}>
              <CloseButton onClick={() => this.deleteFile()}>
                <Icons.XSmall iconColor={theme.colors.grayscale.light1} />
              </CloseButton>
              <Label>{valueName}</Label>
            </FormatterContainer>
          ) : (
            <AddControlLabel
              onClick={() => document.getElementById('file-selector').click()}
            >
              <Icons.PlusSmall iconColor={theme.colors.grayscale.light1} />
              {t('Add file')}
              <input
                id="file-selector"
                type="file"
                onChange={this.onSelectFile}
                style={{ display: 'none', width: '100%' }}
              />
            </AddControlLabel>
          )}
        </FormattersContainer>
      </div>
    );
  }
}

FileControl.propTypes = propTypes;
FileControl.defaultProps = defaultProps;

export default withTheme(FileControl);
