/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useMemo } from 'react';
import { GenericDataType, styled, t } from '@superset-ui/core';
import { Form, FormItem, FormProps } from 'src/components/Form';
import Select from 'src/components/Select/Select';
import { Col, Row } from 'src/components';
import { InputNumber, Input } from 'src/components/Input';
import Button from 'src/components/Button';
import { SketchPicker } from 'react-color';
import { useForm } from 'antd/lib/form/Form';
import Checkbox from 'src/components/Checkbox';
import {
  COMPARATOR_NUMBERS,
  ConditionalFormattingConfig,
  MULTIPLE_VALUE_COMPARATORS,
} from './types';

const FullWidthInput = styled(Input)`
  width: 100%;
`;

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const JustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const gradientOptions = () => [
  { value: 'ascending', label: t('Ascending') },
  { value: 'descending', label: t('Descending') },
  { value: 'without-gradient', label: t('Without gradient') },
];

const numericOperatorOptions = [
  { value: COMPARATOR_NUMBERS.NONE, label: t('None') },
  { value: COMPARATOR_NUMBERS.GREATER_THAN, label: '>' },
  { value: COMPARATOR_NUMBERS.LESS_THAN, label: '<' },
  { value: COMPARATOR_NUMBERS.GREATER_OR_EQUAL, label: '≥' },
  { value: COMPARATOR_NUMBERS.LESS_OR_EQUAL, label: '≤' },
  { value: COMPARATOR_NUMBERS.EQUAL, label: '=' },
  { value: COMPARATOR_NUMBERS.NOT_EQUAL, label: '≠' },
  { value: COMPARATOR_NUMBERS.BETWEEN, label: '< x <' },
  { value: COMPARATOR_NUMBERS.BETWEEN_OR_EQUAL, label: '≤ x ≤' },
  { value: COMPARATOR_NUMBERS.BETWEEN_OR_LEFT_EQUAL, label: '≤ x <' },
  { value: COMPARATOR_NUMBERS.BETWEEN_OR_RIGHT_EQUAL, label: '< x ≤' },
];

const stringOperatorOptions = [
  { value: COMPARATOR_NUMBERS.NONE, label: t('None') },
  { value: COMPARATOR_NUMBERS.EQUAL, label: '=' },
  { value: COMPARATOR_NUMBERS.CONTAINING, label: t('containing') },
  { value: COMPARATOR_NUMBERS.NOT_CONTAINING, label: t('not containing') },
  { value: COMPARATOR_NUMBERS.BEGINS_WITH, label: t('begins with') },
  { value: COMPARATOR_NUMBERS.ENDS_WITH, label: t('ends with') },
];

const targetValueValidator =
  (
    compare: (targetValue: number, compareValue: number) => boolean,
    rejectMessage: string,
  ) =>
  (targetValue: number | string) =>
  (_: any, compareValue: number | string) => {
    if (
      !targetValue ||
      !compareValue ||
      compare(Number(targetValue), Number(compareValue))
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(rejectMessage));
  };

const targetValueLeftValidator = targetValueValidator(
  (target: number, val: number) => target > val,
  t('This value should be smaller than the right target value'),
);

const targetValueRightValidator = targetValueValidator(
  (target: number, val: number) => target < val,
  t('This value should be greater than the left target value'),
);

const isOperatorMultiValue = (operator?: COMPARATOR_NUMBERS) =>
  operator && MULTIPLE_VALUE_COMPARATORS.includes(operator);

const isOperatorNone = (operator?: COMPARATOR_NUMBERS) =>
  !operator || operator === COMPARATOR_NUMBERS.NONE;

const rulesRequired = [{ required: true, message: t('Required') }];

type GetFieldValue = Pick<Required<FormProps>['form'], 'getFieldValue'>;
const rulesTargetValueLeft = [
  { required: true, message: t('Required') },
  ({ getFieldValue }: GetFieldValue) => ({
    validator: targetValueLeftValidator(getFieldValue('targetValueRight')),
  }),
];

const rulesTargetValueRight = [
  { required: true, message: t('Required') },
  ({ getFieldValue }: GetFieldValue) => ({
    validator: targetValueRightValidator(getFieldValue('targetValueLeft')),
  }),
];

const targetValueLeftDeps = ['targetValueRight'];
const targetValueRightDeps = ['targetValueLeft'];

const shouldFormItemUpdate = (
  prevValues: ConditionalFormattingConfig,
  currentValues: ConditionalFormattingConfig,
) =>
  isOperatorNone(prevValues.operator) !==
    isOperatorNone(currentValues.operator) ||
  isOperatorMultiValue(prevValues.operator) !==
    isOperatorMultiValue(currentValues.operator) ||
  prevValues.applyToTextColor !== currentValues.applyToTextColor;

const getOperatorField = (columnType?: GenericDataType) => {
  const options =
    columnType === GenericDataType.STRING
      ? stringOperatorOptions
      : numericOperatorOptions;
  return (
    <FormItem
      name="operator"
      label={t('Operator')}
      rules={rulesRequired}
      initialValue={options[0].value}
    >
      <Select ariaLabel={t('Operator')} options={options} />
    </FormItem>
  );
};

const renderOperatorFields = (
  { getFieldValue }: GetFieldValue,
  columnType?: GenericDataType,
) =>
  isOperatorNone(getFieldValue('operator')) ? (
    <Row gutter={12}>
      <Col span={8}>{getOperatorField(columnType)}</Col>
    </Row>
  ) : isOperatorMultiValue(getFieldValue('operator')) ? (
    <Row gutter={12}>
      <Col span={9}>
        <FormItem
          name="targetValueLeft"
          label={t('Left value')}
          rules={rulesTargetValueLeft}
          dependencies={targetValueLeftDeps}
          validateTrigger="onBlur"
          trigger="onBlur"
        >
          <FullWidthInputNumber />
        </FormItem>
      </Col>
      <Col span={6}>{getOperatorField(columnType)}</Col>
      <Col span={9}>
        <FormItem
          name="targetValueRight"
          label={t('Right value')}
          rules={rulesTargetValueRight}
          dependencies={targetValueRightDeps}
          validateTrigger="onBlur"
          trigger="onBlur"
        >
          <FullWidthInputNumber />
        </FormItem>
      </Col>
    </Row>
  ) : (
    <Row gutter={12}>
      <Col span={9}>{getOperatorField(columnType)}</Col>
      <Col span={15}>
        <FormItem
          name="targetValue"
          label={t('Target value')}
          rules={rulesRequired}
        >
          {columnType === GenericDataType.STRING ? (
            <FullWidthInput />
          ) : (
            <FullWidthInputNumber />
          )}
        </FormItem>
      </Col>
    </Row>
  );

export const FormattingPopoverContent = ({
  config,
  onChange,
  columns = [],
}: {
  config?: ConditionalFormattingConfig;
  onChange: (config: ConditionalFormattingConfig) => void;
  columns: { label: string; value: string; dataType: GenericDataType }[];
}) => {
  const [color, setColor] = React.useState(config?.colorScheme);
  const [applyToTextColor, setApplyToTextColor] = React.useState(
    config?.applyToTextColor || false,
  );
  const [form] = useForm();

  const [column, setColumn] = React.useState<string>(
    config?.column || columns[0]?.value,
  );

  const columnType = useMemo(
    () => columns.find(col => col.value === column)?.dataType,
    [columns, column],
  );

  return (
    <Form
      form={form}
      onFinish={(values: any) => onChange({ ...values, colorScheme: color })}
      initialValues={config}
      requiredMark="optional"
      layout="vertical"
    >
      <Row gutter={12}>
        <Col span={12}>
          <FormItem
            name="column"
            label={t('Column')}
            rules={rulesRequired}
            initialValue={columns[0]?.value}
          >
            <Select
              ariaLabel={t('Select column')}
              options={columns}
              onChange={value => {
                setColumn(value as string);
                form.setFieldsValue({
                  gradientMode: 'without-gradient',
                  operator: COMPARATOR_NUMBERS.NONE,
                });
              }}
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem noStyle shouldUpdate={shouldFormItemUpdate}>
        {(props: GetFieldValue) => renderOperatorFields(props, columnType)}
      </FormItem>
      {columnType !== GenericDataType.STRING && (
        <FormItem noStyle shouldUpdate={shouldFormItemUpdate}>
          <FormItem
            name="gradientMode"
            label={t('Gradient')}
            initialValue={gradientOptions()[0].value}
            rules={rulesRequired}
          >
            <Select
              ariaLabel={t('Color scheme')}
              options={gradientOptions()}
              autoClearSearchValue
            />
          </FormItem>
        </FormItem>
      )}
      <Row>
        <FormItem noStyle shouldUpdate={shouldFormItemUpdate}>
          <FormItem
            name="applyToTextColor"
            label={t('Apply to text color')}
            rules={rulesRequired}
            initialValue={false}
            // @ts-ignore TODO: Set right position for checkbox without using CSS like this
            css={{
              display: 'flex',
              flexDirection: 'row-reverse !important',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <Checkbox
              onChange={checked => {
                form.setFieldsValue({ applyToTextColor: checked });
                setApplyToTextColor(checked || false);
              }}
              checked={applyToTextColor}
            />
          </FormItem>
        </FormItem>
      </Row>
      <Row>
        <Col span={12}>
          <FormItem
            name="colorScheme"
            label={t('Color scheme')}
            rules={rulesRequired}
          >
            <SketchPicker
              onChange={(col: any) => {
                setColor(col.hex);
              }}
              color={color}
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem>
        <JustifyEnd>
          <Button htmlType="submit" buttonStyle="primary">
            {t('Apply')}
          </Button>
        </JustifyEnd>
      </FormItem>
    </Form>
  );
};
