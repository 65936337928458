/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import { styled, t } from '@superset-ui/core';
import { Form, FormItem } from 'src/components/Form';
import { Col, Row } from 'src/components';
import Button from 'src/components/Button';
import { DateRangeConfig } from './types';
import DateFilterControl from '../DateFilterControl';

const JustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const rulesRequired = [{ required: true, message: t('Required') }];

export const DateRangePopoverContent = ({
  config,
  onChange,
}: {
  config?: DateRangeConfig;
  onChange: (config: DateRangeConfig) => void;
}) => (
  <Form initialValues={config} requiredMark="optional" layout="vertical">
    <Row gutter={12}>
      <Col span={12}>
        <FormItem
          name="time_range"
          label={t('Date Range')}
          rules={rulesRequired}
          initialValue="no filter"
        >
          <DateFilterControl
            name="time_range"
            onChange={timeRange => {
              onChange({ time_range: timeRange });
            }}
          />
        </FormItem>
      </Col>
    </Row>
    <FormItem>
      <JustifyEnd>
        <Button htmlType="submit" buttonStyle="primary">
          {t('Apply')}
        </Button>
      </JustifyEnd>
    </FormItem>
  </Form>
);
